import * as Location from 'expo-location';
import Constants from 'expo-constants';

import React, { useEffect, useMemo, useState } from 'react';
import { Image, Platform, Pressable, View, useWindowDimensions, ScrollView } from 'react-native';
import AuthGuard from '../components/common/AuthGuard';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import LoggedInRestrictedArea from '../components/common/LoggedInRestrictedArea';
import { LoggedState } from '../models/AuthModelsCommon';
import useTranslation from '../hooks/translation-hook';
import useStyles from '../hooks/styles-hook';
import Label, { LabelType } from '../components/common/Label';
import {
  GetMyMissionDetailsDto,
  GetMyMissionListDto,
  GetMyMissionsListStatusDto,
  MissionAssignationStatus,
  MissionConfigurationDetailType,
  MyMissionClient,
  PrevalidationSteps,
} from '../api/api.g';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { useMutation, useQuery } from '@tanstack/react-query';
import useApi from '../hooks/api-hook';
import { MapLocation } from '../models/MapModels';
import Loading from '../components/common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faAward,
  faBasketShopping,
  faBullseye,
  faCalendar,
  faCalendarClock,
  faCheckDouble,
  faChevronLeft,
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faCrosshairsSimple,
  faEye,
  faHourglass,
  faHourglassStart,
  faLightbulbExclamationOn,
  faLink,
  faLocation,
  faLocationCheck,
  faMapLocationDot,
  faPenNib,
  faReceipt,
  faStore,
  faTimer,
  faTriangleExclamation,
  faUserSecret,
} from '@fortawesome/pro-regular-svg-icons';
import pin from '../../assets/pin-selected.png';
import CustomMarkdown from '../components/common/CustomMarkDown';
import useCustomTheme from '../hooks/theme-hook';
import NavigationButton from '../components/common/NavigationButton';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { lightBoxActions } from '../store/lightBoxSlice';
import Prevalidation from '../components/validation/pages/Prevalidation';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/mymission/MyMissionItem';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { missionsActions, missionsSlice } from '../store/missionsSlice';
import CustomButton from '../components/common/CustomButton';
import ProfileModal from '../components/myaccount/myprofile/ProfileModal';
import MoreInfoButton from '../components/common/MoreInfoButton';
import { StylesHelper } from '../models/helpers/stylesHelper';
import { StringHelper } from '../helpers/stringHelper';
import { AnalyticsHelper } from '../helpers/analyticsHelper';
import useSharedPopup from '../hooks/sharedPopup-hook';
import { navBarActions } from '../store/navBarSlice';

export enum NavigationState {
  List,
  Details,
}

export enum MissionDetailsState {
  Mission,
  Scenario,
  Conditions,
  Admissibility,
}

const MyMissions = () => {
  const authStore = useAppSelector((selector) => selector.auth);
  const missionsStore = useAppSelector((selector) => selector.missions);

  const t = useTranslation();
  const dispatch = useAppDispatch();
  const tMission = useTranslation({ defaultPath: 'mission' });
  const layout = useWindowDimensions();
  const styles = useStyles((section) => section.myMissions);
  const stylesDetail = useStyles((section) => section.missionDetailCard);
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute();
  const theme = useCustomTheme();
  const sharedPopup = useSharedPopup();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const stylesCard = useStyles((section) => section.missionCard);

  const apiMyMission = useApi(MyMissionClient);

  const [userLocation, setUserLocation] = useState<MapLocation>(undefined);
  const defaultPosition: MapLocation = Constants.expoConfig.extra.defautlLocation;

  const [missionBlocks, setMissionBlocks] = useState<GetMyMissionsListStatusDto[]>();
  const [selectedMissionId, setSelectedMissionId] = useState('');
  const [selectedMission, setSelectedMission] = useState<GetMyMissionListDto>();
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailsComponent, setDetailsComponent] = useState(<></>);
  const [missionDetailContent, setMissionDetailContent] = useState(<></>);
  const [missionAdmissibilityContent, setMissionAdmissibilityContent] = useState(<></>);
  const [missionConditionsContent, setMissionConditionsContent] = useState(<></>);
  const [missionScenarioContent, setMissionScenarioContent] = useState(<></>);

  const [detailsState, setDetailsState] = useState<MissionDetailsState>();
  const [navigationState, setNavigationState] = useState<NavigationState>();
  const [detail, setDetail] = useState<GetMyMissionDetailsDto>();
  const [postponeLoading, setPostponeLoading] = useState<boolean>(false);
  const [extendMissionFailed, setExtendMissionFailed] = useState<boolean>(false);

  const defaultLogo = useMemo(() => <FontAwesomeIcon icon={faStore} style={stylesCard.header.logo.icon} />, [stylesCard]);

  // const isFocused = useIsFocused();
  // const queryClient = useQueryClient();
  // const navigationRef = createNavigationContainerRef();

  const [navigationEnd, setNavigationEnd] = useState(false);
  const [shouldShowAnonymousPopup, setShouldShowAnonymousPopup] = useState(false);
  const [refreshMap, setRefreshMap] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      if (authStore.logged !== undefined && authStore.logged !== LoggedState.NotLogged) {
        console.log(`%cNavigation end - ${route.name}`, 'color: #ffa600;');

        AnalyticsHelper.logScreenView(route.name).then(() => {
          console.log('Analytics', 'Page view: My missions');
        });

        if (missionsStore.showOnARollPopup) {
          sharedPopup.showOnARollPopup();
        }

        if (route?.params && route?.params['id']) {
          setSelectedMissionId(route?.params['id']);
        } else {
          if (!missionsStore.recentlyAcceptedMission && layout.width <= 768) {
            setSelectedMissionId('');
          }
        }

        setRefreshMap(true);
        setNavigationEnd(true);
        dispatch(navBarActions.setVisibility(true));
      } else {
        setNavigationEnd(false);
      }
    }, [authStore.logged, route.params])
  );

  // React.useEffect(() => {
  //   const unsubscribe = navigation.addListener('blur', () => {
  //     console.log(`%cOnBlur - ${route.name}`, 'color: #ffa600;');
  //     setNavigationEnd(false);
  //     queryClient.cancelQueries({ queryKey: ['myMissions'] });
  //   });

  //   return unsubscribe;
  // }, [navigation]);

  useEffect(() => {
    if (missionsStore.recentlyAcceptedMission) {
      setSelectedMissionId(missionsStore.recentlyAcceptedMission);
    }
  }, [missionsStore.recentlyAcceptedMission]);

  useEffect(() => {
    Location.requestForegroundPermissionsAsync().then((result) => {
      if (result.status !== Location.PermissionStatus.GRANTED) {
        setUserLocation(defaultPosition);
        return;
      }

      Location.getCurrentPositionAsync({}).then((currentLocation) => {
        setUserLocation({ lat: currentLocation.coords.latitude, lng: currentLocation.coords.longitude });
      });
    });

    setDetailsState(MissionDetailsState.Mission);

    setMissionDetailContent(
      <>
        {detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Objective) &&
          detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Objective)?.text !== '' && (
            <View style={styles.newVersion.details.content.details.infoBlocksBase}>
              <View style={styles.newVersion.details.content.details.infoBlocks.title}>
                <FontAwesomeIcon icon={faBullseye} style={styles.newVersion.details.content.details.infoBlocks.titleIcon} />
                <Label variant={LabelType.titleDark}>{t('Your-objective')}</Label>
                <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                  {`${t('help.your-objective')}`}
                </MoreInfoButton>
              </View>
              <View style={styles.newVersion.details.content.details.infoBlocks.content}>
                <CustomMarkdown>{detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Objective)?.text}</CustomMarkdown>
              </View>
            </View>
          )}
        {detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.ToNote) &&
          detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.ToNote)?.text !== '' && (
            <View style={styles.newVersion.details.content.details.infoBlocksBase}>
              <View style={styles.newVersion.details.content.details.infoBlocks.title}>
                <FontAwesomeIcon icon={faPenNib} style={styles.newVersion.details.content.details.infoBlocks.titleIcon} />
                <Label variant={LabelType.titleDark}>{t('Please-note')}</Label>
                <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                  {`${t('help.please-note')}`}
                </MoreInfoButton>
              </View>
              <View style={styles.newVersion.details.content.details.infoBlocks.content}>
                <CustomMarkdown>{detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.ToNote)?.text}</CustomMarkdown>
              </View>
            </View>
          )}
        {detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.NiceToKnow) &&
          detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.NiceToKnow)?.text !== '' && (
            <View style={styles.newVersion.details.content.details.infoBlocksBase}>
              <View style={styles.newVersion.details.content.details.infoBlocks.title}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={styles.newVersion.details.content.details.infoBlocks.titleIcon} />
                <Label variant={LabelType.titleDark}>{t('Pay-attention')}</Label>
                <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                  {`${t('help.nice-to-know')}`}
                </MoreInfoButton>
              </View>
              <View style={styles.newVersion.details.content.details.infoBlocks.content}>
                <CustomMarkdown>{detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.NiceToKnow)?.text}</CustomMarkdown>
              </View>
            </View>
          )}
        {detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Scenario) &&
          detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Scenario)?.text !== '' && (
            <View style={styles.newVersion.details.content.details.infoBlocksBase}>
              <View style={styles.newVersion.details.content.details.infoBlocks.title}>
                <FontAwesomeIcon icon={faUserSecret} style={styles.newVersion.details.content.details.infoBlocks.titleIcon} />
                <Label variant={LabelType.titleDark}>{t('The-scenario')}</Label>
                <MoreInfoButton style={{ marginLeft: 8 }} title={''} openURL={'the-scenario-lanla-website-support-link'}>
                  {t('help.the-scenario')}
                </MoreInfoButton>
              </View>
              <View style={styles.newVersion.details.content.details.infoBlocks.content}>
                <CustomMarkdown>{detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Scenario)?.text}</CustomMarkdown>
                <Pressable style={styles.newVersion.details.content.details.infoBlocks.link} onPress={() => setDetailsState(MissionDetailsState.Scenario)}>
                  <Label variant={LabelType.caption}>{t('Read-more')}</Label>
                </Pressable>
              </View>
            </View>
          )}
        <View style={styles.newVersion.details.content.details.infoBlocks.baseLinks}>
          {detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != undefined &&
            detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != '' && (
              <Pressable style={styles.newVersion.details.content.details.infoBlocks.link} onPress={() => setDetailsState(MissionDetailsState.Admissibility)}>
                <Label variant={LabelType.caption}>{t('Admissibility')}</Label>
              </Pressable>
            )}
          {detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != undefined &&
            detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text != '' &&
            detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != undefined &&
            detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != '' && (
              <Label variant={LabelType.caption}>&nbsp;{t('and')}&nbsp;</Label>
            )}
          {detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != undefined &&
            detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text != '' && (
              <Pressable style={styles.newVersion.details.content.details.infoBlocks.link} onPress={() => setDetailsState(MissionDetailsState.Conditions)}>
                <Label variant={LabelType.caption} style={{ textTransform: 'lowercase' }}>
                  {t('conditions')}
                </Label>
              </Pressable>
            )}
        </View>
      </>
    );

    let admissibilityText = detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text;

    if (!admissibilityText || admissibilityText.trim() === '') {
      admissibilityText = 'Default Admissibility';
    }

    setMissionAdmissibilityContent(
      <>
        <Label variant={LabelType.title} style={styles.newVersion.details.header.subTitle}>
          {t('Admissibility')}
        </Label>
        <CustomMarkdown>{admissibilityText}</CustomMarkdown>
        <NavigationButton
          style={styles.newVersion.details.content.details.infoBlocks.baseLinks}
          icon={faChevronLeft}
          iconSize={16}
          color={theme.text.titleLight}
          labelVariant={LabelType.titleSmall}
          onPress={() => setDetailsState(MissionDetailsState.Mission)}
          text={t('back')}
        />
      </>
    );

    let conditionsText = detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text;

    if (!conditionsText || conditionsText.trim() === '') {
      conditionsText = 'Default Terms and Conditions';
    }

    setMissionConditionsContent(
      <>
        <Label variant={LabelType.title} style={styles.newVersion.details.header.subTitle}>
          {t('Terms-and-Conditions')}
        </Label>
        <CustomMarkdown>{conditionsText}</CustomMarkdown>
        <NavigationButton
          style={styles.newVersion.details.content.details.infoBlocks.baseLinks}
          icon={faChevronLeft}
          iconSize={16}
          color={theme.text.titleLight}
          labelVariant={LabelType.titleSmall}
          onPress={() => setDetailsState(MissionDetailsState.Mission)}
          text={t('back')}
        />
      </>
    );

    setMissionScenarioContent(
      <>
        <Label variant={LabelType.title} style={styles.newVersion.details.header.subTitle}>
          {t('scenario')}
        </Label>
        <CustomMarkdown>{detail?.missionDetails?.find((x) => x.type === MissionConfigurationDetailType.Scenario)?.text}</CustomMarkdown>
        <NavigationButton
          style={[styles.newVersion.details.content.details.infoBlocks.baseLinks, { marginTop: 20 }]}
          icon={faChevronLeft}
          iconSize={16}
          color={theme.text.titleLight}
          labelVariant={LabelType.titleSmall}
          onPress={() => setDetailsState(MissionDetailsState.Mission)}
          text={t('back')}
        />
      </>
    );
  }, [detail]);

  useEffect(() => {
    switch (detailsState) {
      case MissionDetailsState.Mission:
        setDetailsComponent(missionDetailContent);
        break;
      case MissionDetailsState.Admissibility:
        setDetailsComponent(missionAdmissibilityContent);
        break;
      case MissionDetailsState.Conditions:
        setDetailsComponent(missionConditionsContent);
        break;
      case MissionDetailsState.Scenario:
        setDetailsComponent(missionScenarioContent);
        break;
    }
  }, [detailsState, missionDetailContent, missionAdmissibilityContent, missionConditionsContent, missionScenarioContent]);

  useEffect(() => {
    if (!navigationState) {
      if (layout.width < 768) {
        setNavigationState(NavigationState.List);
      }
    }
  }, [layout.width]);

  useEffect(() => {
    if (authStore.logged === LoggedState.Anonymous) {
      setShouldShowAnonymousPopup(true);
    } else {
      setShouldShowAnonymousPopup(false);
    }
  }, [authStore.logged]);

  const myMissionsQuery = useQuery({
    enabled: !!navigationEnd && !!authStore.idToken && !!missionsStore.missionListSeed,
    queryKey: ['myMissions', 'list', authStore.idToken, missionsStore.missionListSeed],
    queryFn: ({ signal }) => {
      setLoading(true);

      setNavigationState(NavigationState.List);

      dispatch(missionsActions.setReloadRejectedValidationSeed(StringHelper.generateRandomString()));

      const promise = apiMyMission.getMissionsList(apiMyMission.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        setLoading(false);

        apiMyMission.tokenSource?.cancel('Query was cancelled by React Query');
        apiMyMission.onCancel();
      });

      return promise.finally(() => {
        setLoading(false);
      });
    },
  });

  const missionDetailQuery = useQuery({
    enabled: !!navigationEnd && !!selectedMissionId && !!authStore.idToken && authStore.logged > 1,
    queryKey: ['myMissionsDetail', selectedMissionId, missionsStore.missionListSeed],
    queryFn: ({ signal }) => {
      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiMyMission.tokenSource?.cancel('Query was cancelled by React Query');
        apiMyMission.onCancel();
      });

      setLoadingDetail(true);

      return apiMyMission.getMissionDetails(selectedMissionId, apiMyMission.tokenSource?.token);
    },
    // useErrorBoundary: (error: any) => {
    //   if (error.status === 404) {
    //     dispatch(lightBoxActions.setContent(<Label>Error</Label>));
    //     dispatch(lightBoxActions.setVisibility(true));
    //     console.log(' test');
    //   }
    // },
  });

  useEffect(() => {
    if (myMissionsQuery.isSuccess) {
      if (myMissionsQuery.data) {
        const value = myMissionsQuery.data;
        const missionsListArray = myMissionsQuery.data.missions.reduce((accumulator, currentMission) => {
          // Check if missionsList array is defined and not empty
          if (currentMission.missionsList && currentMission.missionsList.length > 0) {
            // Concatenate the missionsList array to the accumulator
            return accumulator.concat(currentMission.missionsList);
          } else {
            return accumulator;
          }
        }, []);

        setMissionBlocks(value.missions?.sort((a, b) => (a.status > b.status ? 1 : -1)));

        if (missionsListArray?.length > 0) {
          if (selectedMissionId && missionsListArray.findIndex((m) => m.id === selectedMissionId) > -1) {
            setSelectedMissionId(selectedMissionId);
            setSelectedMission(missionsListArray.find((m) => m.id === selectedMissionId));
            dispatch(missionsActions.setMissionAcceptedId(undefined));

            if (layout.width <= 768) {
              setNavigationState(NavigationState.Details);
            }
          }
        } else {
          setMissionBlocks(undefined);
        }

        if (!selectedMissionId) {
          setLoading(false);
        }
      }
    }

    if (myMissionsQuery.isError) {
      setLoading(false);
    }
  }, [myMissionsQuery.isSuccess, myMissionsQuery.isError, myMissionsQuery.data, selectedMissionId]);

  useEffect(() => {
    if (missionDetailQuery.isSuccess) {
      const missionDetail = (missionDetailQuery.data as any).missionDetails;
      setDetail(missionDetail);

      setLoadingDetail(false);
      setLoading(false);
    }

    if (missionDetailQuery.isError) {
      setLoadingDetail(false);
    }
  }, [missionDetailQuery.isSuccess, missionDetailQuery.isError, missionDetailQuery.data]);

  useEffect(() => {
    if (
      missionBlocks?.length > 0 &&
      missionBlocks[0].missionsList.length > 0 &&
      ((!selectedMissionId && !(route?.params && route?.params['id'])) ||
        missionBlocks.map((m) => m.missionsList).every((m) => m.findIndex((m) => m.id === selectedMissionId) === -1)) &&
      layout.width > 768
    ) {
      setSelectedMissionId(missionBlocks[0].missionsList[0].id);
      setSelectedMission(missionBlocks[0].missionsList[0]);
    }
  }, [missionBlocks]);

  const cancelMissionMutation = useMutation({
    mutationFn: (missionId: string) => {
      return apiMyMission.cancelMyMission(missionId, apiMyMission.tokenSource?.token);
    },
    onSuccess() {
      setLoadingDetail(true);
      setNavigationState(NavigationState.List);
      dispatch(missionsActions.setReloadMissionListSeed(String.prototype.generateRandomString(5)));
    },
  });

  const delayMissionMutation = useMutation({
    mutationFn: (params: { missionId: string; requestDay: number }) => {
      setExtendMissionFailed(false);
      setPostponeLoading(true);
      return apiMyMission.extendMyMission(params.missionId, params.requestDay, apiMyMission.tokenSource?.token);
    },
    onSuccess(data, variables) {
      setPostponeLoading(false);
      dispatch(missionsActions.setReloadMissionListSeed(String.prototype.generateRandomString(5)));

      dispatch(
        lightBoxActions.setContent(
          <ProfileModal hideConfirm title={t('mission-extended')}>
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={faCalendarClock} size={24} color={theme.text.titleDark.toString()} />
              <Label style={{ marginLeft: 8 }} variant={LabelType.body1}>
                {t('mission-delay-confirm', { count: variables.requestDay })}
              </Label>
            </View>
          </ProfileModal>
        )
      );

      dispatch(lightBoxActions.setVisibility(true));
    },
    onError() {
      setPostponeLoading(false);
      setExtendMissionFailed(true);
    },
  });

  const showPrompt = () => {
    if (detail.status === MissionAssignationStatus.ValidationRejected || detail.inProgress) {
      navigation.navigate(detail.status === MissionAssignationStatus.ValidationRejected ? 'editSurvey' : 'survey', { missionAssignationId: selectedMissionId });
    } else {
      dispatch(
        lightBoxActions.setContent(
          <Prevalidation
            refreshMap={refreshMap}
            onMapRefreshed={() => {
              setRefreshMap(false);
            }}
            phone={selectedMission?.phone}
            selectedMissionId={selectedMissionId}
            onClosePress={() => {
              dispatch(lightBoxActions.setVisibility(false));
              dispatch(lightBoxActions.setContent(<></>));
            }}
            onConfirmPress={() => {
              dispatch(lightBoxActions.setVisibility(false));
              dispatch(lightBoxActions.setContent(<></>));

              navigation.navigate(detail.status === MissionAssignationStatus.ValidationRejected ? 'editSurvey' : 'survey', {
                missionAssignationId: selectedMissionId,
              });
            }}
          />
        )
      );
      dispatch(lightBoxActions.setVisibility(true));
    }
  };

  const getTimeLineItem = (title: string, icon: IconProp, date?: Date) => {
    let status = detail.status;
    let iconBaseStyle = {};
    let dottedLineStyle = {};
    let contentStyle = {};
    let itemStyle = {};
    let iconColor = theme.timelineIcon.toString();
    let hasDottedLine = true;
    let fillSurvey = false;
    let validationFailed = false;
    let supportText = '';
    let supportLink = undefined;

    if (title === 'Mission-accepted') {
      dottedLineStyle = styles.newVersion.details.content.timeline.dottedBottom;
      hasDottedLine = true;
      supportText = `${t('help.mission-deadline')}`;
      supportLink = 'mission-deadline-lanla-website-support-link';
    }

    if (title === 'Mission-accomplished') {
      supportText = `${t('help.mission-accomplished')}`;

      if (status === MissionAssignationStatus.Assigned) {
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.active;
        itemStyle = styles.newVersion.details.content.timeline.contentActive;
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedUp;
        iconColor = theme.text.titleDark.toString();
        hasDottedLine = true;
        fillSurvey = true;
        supportText = `${t('help.complete-the-questionnaire')}`;
        supportLink = 'complete-the-questionnaire-lanla-website-support-link';
      }

      if (status >= MissionAssignationStatus.Prevalidation) {
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedBoth;
        hasDottedLine = true;
      }
    }

    if (title === 'Mission-in-prevalidation') {
      supportText = `${t('help.mission-in-prevalidation')}`;
      supportLink = 'mission-under-validation-lanla-website-support-link';
      if (status === MissionAssignationStatus.Prevalidation) {
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.active;
        itemStyle = styles.newVersion.details.content.timeline.contentActive;
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedUp;
        iconColor = theme.text.titleDark.toString();
        hasDottedLine = true;
      }

      if (status >= MissionAssignationStatus.Validating) {
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedBoth;
        hasDottedLine = true;
      }
      if (status < MissionAssignationStatus.Prevalidation) {
        contentStyle = styles.newVersion.details.content.timeline.contentDisabled;
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.disabled;
        iconColor = theme.text.darkTurquoiseTwo.toString();
        hasDottedLine = false;
      }
      if (status === MissionAssignationStatus.PrevalidationFailed) {
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedUp;
        hasDottedLine = true;
        icon = faCircleXmark;
        itemStyle = styles.newVersion.details.content.timeline.contentError;
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.disabled;
        iconColor = theme.text.error.toString();
      }
    }

    if (title === 'Mission-in-validation') {
      supportText = `${t('help.mission-under-validation')}`;
      supportLink = 'mission-under-validation-lanla-website-support-link';
      if (status === MissionAssignationStatus.Validating) {
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.active;
        itemStyle = styles.newVersion.details.content.timeline.contentActive;
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedUp;
        iconColor = theme.text.titleDark.toString();
        hasDottedLine = true;
      }

      if (status === MissionAssignationStatus.Approved) {
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedBoth;
        hasDottedLine = true;
      }

      if (status < MissionAssignationStatus.Validating || status === MissionAssignationStatus.PrevalidationFailed) {
        contentStyle = styles.newVersion.details.content.timeline.contentDisabled;
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.disabled;
        iconColor = theme.text.darkTurquoiseTwo.toString();
        hasDottedLine = false;
      }

      if (status === MissionAssignationStatus.ValidationRejected) {
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedUp;
        hasDottedLine = true;
        icon = faCircleXmark;
        itemStyle = styles.newVersion.details.content.timeline.contentError;
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.disabled;
        iconColor = theme.text.error.toString();
        validationFailed = true;
        supportText = `${t('help.mission-under-validation-correct-the-questionnaire')}`;
      }
    }

    if (title === 'Mission-approved') {
      if (status === MissionAssignationStatus.Approved) {
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.active;
        itemStyle = styles.newVersion.details.content.timeline.contentActive;
        dottedLineStyle = styles.newVersion.details.content.timeline.dottedUp;
        iconColor = theme.text.titleDark.toString();
        hasDottedLine = true;
      } else {
        contentStyle = styles.newVersion.details.content.timeline.contentDisabled;
        iconBaseStyle = styles.newVersion.details.content.timeline.icon.disabled;
        iconColor = theme.text.darkTurquoiseTwo.toString();
        hasDottedLine = false;
      }
      supportText = `${t('help.approved-mission')}`;
      supportLink = `mission-approved-lanla-website-support`;
    }

    return (
      <View style={[styles.newVersion.details.content.timeline.item, itemStyle]}>
        {hasDottedLine && <View style={[styles.newVersion.details.content.timeline.dottedLine, dottedLineStyle]}></View>}
        <View style={[styles.newVersion.details.content.timeline.iconBase, iconBaseStyle]}>
          <FontAwesomeIcon icon={icon} size={Platform.OS === 'web' ? 20 : 18} color={iconColor} />
        </View>
        <View style={styles.newVersion.details.content.timeline.content}>
          {fillSurvey || validationFailed ? (
            <>
              {detail.daysLeft !== null && (
                <>
                  <Label variant={LabelType.titleSmall} style={[contentStyle, { fontWeight: '400', flexShrink: 1 }]} numberOfLines={2}>
                    {validationFailed ? t('Validation-failed') : t('Mission-expires-the')}
                  </Label>
                  {!validationFailed && (
                    <Label variant={LabelType.titleSmall} style={[contentStyle, { fontWeight: '500', flexShrink: 1 }]} numberOfLines={2}>
                      {detail.endDate.toLocaleString()}
                    </Label>
                  )}
                </>
              )}
              <Pressable onPress={showPrompt}>
                <Label variant={LabelType.body1} style={{ fontWeight: '600', textDecorationLine: 'underline' }}>
                  {validationFailed ? t('Correct-my-questionnaire') : t('Complete-the-questionnaire')}
                </Label>
              </Pressable>
            </>
          ) : (
            <>
              <Label variant={LabelType.titleSmall} style={contentStyle}>
                {t(title)}
              </Label>
              <Label variant={LabelType.body1}>{date?.toLocaleDateString()}</Label>
            </>
          )}
        </View>
        <MoreInfoButton style={{ marginLeft: 8 }} title={''} openURL={supportLink}>
          {supportText}
        </MoreInfoButton>
      </View>
    );
  };

  const getLogo = (logoUrl, hasLogo) => {
    if (logoUrl && hasLogo) {
      return <Image style={styles.newVersion.list.logo.image} source={{ uri: logoUrl }} />;
    } else {
      return defaultLogo;
    }
  };

  const handleDelayMissionClick = (missionId: string, maxDays: number) => {
    delayMissionMutation.mutate({ missionId, requestDay: maxDays });
  };

  const showConfirmationPrompt = () => {
    dispatch(
      lightBoxActions.setContent(
        <ProfileModal
          onConfirm={() => {
            cancelMissionMutation.mutate(selectedMissionId);
            dispatch(lightBoxActions.setVisibility(false));
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faTriangleExclamation} size={48} color={theme.colorErrorPrimary.toString()} />
            <Label style={{ marginTop: 8 }}>{t('are-you-sure-you-want-to-cancel-the-mission')} </Label>
            <Label variant={LabelType.titlePrimarySmall} style={{ marginTop: 8, textAlign: 'center' }}>
              {t('it-will-be-returned-online')}
            </Label>
          </View>
        </ProfileModal>
      )
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  const getPrevalidationIcon = (enumFlag: PrevalidationSteps, prevalidationSteps: PrevalidationSteps) => {
    const isStepActive = (enumFlag: PrevalidationSteps, step: PrevalidationSteps) => {
      return (enumFlag & step) === step;
    };

    if (detail.status === MissionAssignationStatus.Prevalidation) {
      return <FontAwesomeIcon icon={faTimer} size={24} color={'rgb(245, 165, 35)'} />;
    } else if (isStepActive(enumFlag, prevalidationSteps)) {
      return <FontAwesomeIcon icon={faCircleXmark} size={24} color={'#8e183d'} />;
    } else {
      return <FontAwesomeIcon icon={faCircleCheck} size={24} color={'#035164'} />;
    }
  };

  const getPrevalidationText = (enumFlag: PrevalidationSteps, prevalidationSteps: PrevalidationSteps, customText: string) => {
    const isStepActive = (enumFlag: PrevalidationSteps, step: PrevalidationSteps) => {
      return (enumFlag & step) === step;
    };
    if (detail.status === MissionAssignationStatus.Prevalidation) {
      return <Label style={{ color: 'rgb(245, 165, 35)', fontSize: 16, flexWrap: 'wrap' }}>{t('Pending')}</Label>;
    } else if (isStepActive(enumFlag, prevalidationSteps)) {
      return (
        <Label numberOfLines={20} style={{ color: '#8e183d', fontSize: 16, flexWrap: 'wrap' }}>
          {customText}
        </Label>
      );
    } else {
      return <Label style={{ color: '#035164', fontSize: 16, flexWrap: 'wrap' }}>{t('Approved')}</Label>;
    }
  };

  const getEmptyComponent = () => {
    return (
      <View
        style={{
          height: '100%',
          flexGrow: 1,
          flexShrink: 1,
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          ...StylesHelper.padding(16, 24),
        }}
      >
        <Label variant={LabelType.titleLargeNoCapsPrimary} style={styles.newVersion.list.title}>
          {t('my_missions')}
        </Label>
        <ScrollView style={{ flexGrow: 1, flexShrink: 1, marginTop: 16 }}>
          <View style={{ alignItems: 'center', flexShrink: 0 }}>
            <Image style={{ width: 229, height: 274, alignSelf: 'center' }} source={require('../../assets/NoValidations.png')} />
            {/* <Label variant={LabelType.titleLargeNoCapsPrimary} style={{ textAlign: 'center', fontSize: 30, flexShrink: 1 }}>
              {t('oh')}
            </Label> */}
            <Label variant={LabelType.titleLargeLight} style={{ textAlign: 'center', fontWeight: '700', fontSize: 20, flexShrink: 1, marginTop: 16 }}>
              {tMission('no_missions_assigned')}
            </Label>
            <Label style={{ textAlign: 'center', alignSelf: 'center', fontSize: 14, width: 175, paddingVertical: 24, flexShrink: 1 }} numberOfLines={5}>
              {t('oppotunity-explore-missions')}
            </Label>
            <View style={{ flexDirection: 'row', marginTop: 24 }}>
              <CustomButton
                title={t('missions-available')}
                onPress={() => {
                  navigation.navigate('map' as never);
                }}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    );
  };

  const getDetailComponent = () => {
    return (
      <>
        {detail.status == MissionAssignationStatus.Approved && detailsState !== MissionDetailsState.Scenario ? (
          <>
            <View style={styles.newVersion.details.content.rightContainer}>
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  padding: 16,
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <ScrollView style={{ flexGrow: 1, flexShrink: 1 }}>
                  <View style={{ flexShrink: 0, alignItems: 'center' }}>
                    <View style={{ position: 'relative' }}>
                      <Image style={{ width: 290, height: undefined, aspectRatio: 586 / 544 }} source={require('../../assets/approved.png')} />
                      <Label
                        variant={LabelType.titleLargeNoCapsPrimary}
                        style={{
                          position: 'absolute',
                          top: 63,
                          left: 187,
                          transform: [{ rotate: '-12deg' }],
                          fontSize: 20,
                          color: theme.text.darkTurquoiseTwo,
                          textAlign: 'center',
                          width: 90,
                          fontWeight: 900,
                        }}
                      >
                        {detail.totalAmount ? detail.totalAmount?.toLocaleString('fr-FR') : '0'}
                      </Label>
                    </View>
                    <Label
                      variant={LabelType.titleLargeNoCapsPrimary}
                      style={{
                        textAlign: 'center',
                        fontSize: 35,
                        color: theme.text.darkTurquoiseTwo,
                        flexShrink: 1,
                        marginTop: 10,
                      }}
                    >
                      {t('Congratulations')}
                    </Label>
                    <Label
                      variant={LabelType.titleLargeNoCapsPrimary}
                      style={{
                        textAlign: 'center',
                        fontSize: 25,
                        color: theme.text.sea,
                        flexShrink: 1,
                      }}
                    >
                      {t('Mission-approved')}
                    </Label>
                    <Label
                      style={{ textAlign: 'center', alignSelf: 'center', fontSize: 16, width: 220, paddingVertical: 18, flexShrink: 0, fontWeight: 500 }}
                      numberOfLines={5}
                    >
                      {t('Earn-even-more-by-completing-new-missions')}
                    </Label>
                    <View style={{ flexDirection: 'row', marginTop: 10 }}>
                      <CustomButton
                        title={t('missions-available')}
                        onPress={() => {
                          navigation.navigate('map' as never);
                        }}
                      />
                    </View>
                  </View>
                </ScrollView>
              </View>
            </View>
          </>
        ) : (detail.status === MissionAssignationStatus.Prevalidation || detail.status === MissionAssignationStatus.PrevalidationFailed) &&
          detailsState !== MissionDetailsState.Scenario ? (
          <View style={{ flexShrink: 1, width: '100%', maxWidth: 900 }}>
            <View style={{ borderRadius: 8, padding: 16, backgroundColor: 'rgb(235, 244, 243)' }}>
              <Label variant={LabelType.titleLargeDark} style={{ fontWeight: 700 }}>
                {t('Pre-validation')}
              </Label>
              <View style={{ marginTop: 20 }}>
                <View style={{ minHeight: 80, paddingVertical: 16, borderTopWidth: 2, borderTopColor: '#ffffff', display: 'flex', flexDirection: 'row' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 50 }}>
                    {getPrevalidationIcon(detail.prevalidationSteps, PrevalidationSteps.Bill)}
                  </View>
                  <View style={{ flexShrink: 1, width: '100%' }}>
                    <Label style={{ fontSize: 16, fontWeight: 700 }}>{t('Bill')}</Label>
                    {getPrevalidationText(detail.prevalidationSteps, PrevalidationSteps.Bill, t('bill-prevalidation-failed'))}
                  </View>
                </View>
                <View style={{ minHeight: 80, paddingVertical: 16, borderTopWidth: 2, borderTopColor: '#ffffff', display: 'flex', flexDirection: 'row' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 50 }}>
                    {getPrevalidationIcon(detail.prevalidationSteps, PrevalidationSteps.WrongPlace)}
                  </View>
                  <View style={{ flexShrink: 1, width: '100%' }}>
                    <Label style={{ fontSize: 16, fontWeight: 700 }}>{t('Place')}</Label>
                    {getPrevalidationText(detail.prevalidationSteps, PrevalidationSteps.WrongPlace, t('place-prevalidation-failed'))}
                  </View>
                </View>
                <View style={{ minHeight: 80, paddingVertical: 16, borderTopWidth: 2, borderTopColor: '#ffffff', display: 'flex', flexDirection: 'row' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 50 }}>
                    {getPrevalidationIcon(detail.prevalidationSteps, PrevalidationSteps.DateAndTime)}
                  </View>
                  <View style={{ flexShrink: 1, width: '100%' }}>
                    <Label style={{ fontSize: 16, fontWeight: 700 }}>{t('Date-and-time')}</Label>
                    {getPrevalidationText(detail.prevalidationSteps, PrevalidationSteps.DateAndTime, t('date-and-time-prevalidation-failed'))}
                  </View>
                </View>
                <View style={{ minHeight: 80, paddingVertical: 16, borderTopWidth: 2, borderTopColor: '#ffffff', display: 'flex', flexDirection: 'row' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 50 }}>
                    {getPrevalidationIcon(detail.prevalidationSteps, PrevalidationSteps.FraudDetection)}
                  </View>
                  <View style={{ flexShrink: 1, width: '100%' }}>
                    <Label style={{ fontSize: 16, fontWeight: 700 }}>{t('Fraud-detection')}</Label>
                    {getPrevalidationText(detail.prevalidationSteps, PrevalidationSteps.FraudDetection, t('fraud-prevalidation-failed'))}
                  </View>
                </View>
                <View style={{ minHeight: 80, paddingVertical: 16, borderTopWidth: 2, borderTopColor: '#ffffff', display: 'flex', flexDirection: 'row' }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: 50 }}>
                    {getPrevalidationIcon(detail.prevalidationSteps, PrevalidationSteps.IncorrectProduct)}
                  </View>
                  <View style={{ flexShrink: 1, width: '100%' }}>
                    <Label style={{ fontSize: 16, fontWeight: 700 }}>{t('Product')}</Label>
                    {getPrevalidationText(detail.prevalidationSteps, PrevalidationSteps.IncorrectProduct, t('product-prevalidation-failed'))}
                  </View>
                </View>
              </View>
            </View>
            {detail.status === MissionAssignationStatus.PrevalidationFailed && (
              <View style={{ borderRadius: 8, padding: 20, backgroundColor: '#feeef0', marginTop: 15, display: 'flex', alignItems: 'center' }}>
                <Image style={{ width: 100, aspectRatio: 188 / 137 }} source={require('../../assets/target-prevalidation.png')} />
                <Label style={{ color: '#f05869', fontSize: 28, fontWeight: 700 }}>{t('Oops')}</Label>
                <Label style={{ color: '#8e183d', fontSize: 24, fontWeight: 700 }}>{t('Pre-validation-failure')}</Label>
                <Label numberOfLines={20} style={{ marginTop: 20, fontSize: 16, flexWrap: 'wrap', textAlign: 'center', paddingHorizontal: 20 }}>
                  {t('prevalidation-failed-general-text')}
                </Label>
                <View style={{ flexDirection: layout.width >= 1060 ? 'row' : 'column', marginTop: 16, gap: 10 }}>
                  <CustomButton
                    title={t('Correct-my-questionnaire')}
                    style={{ backgroundColor: '#8e183d', borderColor: '#8e183d' }}
                    textStyle={{ color: '#ffffff' }}
                    onPress={showPrompt}
                  />
                  <CustomButton
                    title={t('Cancel-mission')}
                    style={{ backgroundColor: 'none', borderColor: '#8e183d' }}
                    textStyle={{ color: '#8e183d' }}
                    onPress={() => {
                      showConfirmationPrompt();
                    }}
                  />
                </View>
              </View>
            )}
          </View>
        ) : (
          <>
            {(detail.status !== MissionAssignationStatus.Validating && detail.status !== MissionAssignationStatus.ValidationRejected) ||
            detailsState === MissionDetailsState.Scenario ? (
              <View style={styles.newVersion.details.content.rightContainer}>
                <View style={styles.newVersion.details.content.details.container}>
                  <View style={styles.newVersion.details.content.details.container}>{detailsComponent}</View>
                </View>
              </View>
            ) : (
              <View style={styles.newVersion.details.content.rightContainer}>
                <View
                  style={{
                    flexGrow: 1,
                    flexShrink: 1,
                    padding: 16,
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ScrollView style={{ flexGrow: 1, flexShrink: 1 }}>
                    <View style={{ flexShrink: 0, alignItems: 'center' }}>
                      <Image style={{ width: 229, height: 274 }} source={require('../../assets/InValidations.png')} />
                      <Label
                        variant={LabelType.titleLargeNoCapsPrimary}
                        style={{
                          textAlign: 'center',
                          fontSize: 30,
                          color: detail.status === MissionAssignationStatus.ValidationRejected ? theme.text.error : theme.text.sea,
                        }}
                      >
                        {detail.status === MissionAssignationStatus.ValidationRejected ? t('Validation-failed') : t('Mission-in-validation')}
                      </Label>
                      {detail.status !== MissionAssignationStatus.ValidationRejected ? (
                        <>
                          <Label style={{ textAlign: 'center', alignSelf: 'center', fontSize: 14, width: 175, paddingVertical: 24 }}>
                            {t('oppotunity-explore-missions')}
                          </Label>

                          <View style={{ flexDirection: 'row', marginTop: 24 }}>
                            <CustomButton
                              title={t('missions-available')}
                              onPress={() => {
                                navigation.navigate('map' as never);
                              }}
                            />
                          </View>
                        </>
                      ) : (
                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                          <Label numberOfLines={5} style={{ flexShrink: 1, marginTop: 24, textAlign: 'center' }}>
                            {t('Unfortunately-your-recent-mystery-shopping-survey')}
                          </Label>
                          <View style={{ flexDirection: 'row', marginTop: 24 }}>
                            <CustomButton
                              title={t('Correct-my-questionnaire')}
                              onPress={() => {
                                showPrompt();
                              }}
                            />
                          </View>
                        </View>
                      )}
                    </View>
                  </ScrollView>
                </View>
              </View>
            )}
          </>
        )}
      </>
    );
  };

  const getMissionExpirationExt = (daysLeft?: number) => {};

  return (
    <AuthGuard>
      {shouldShowAnonymousPopup && navigationEnd && <LoggedInRestrictedArea message={t('need-login-before', { action: t('see-your-missions') })} />}
      {!shouldShowAnonymousPopup && navigationEnd && (
        <>
          {layout.width <= 768 && navigationState === NavigationState.Details && (
            <NavigationButton
              style={styles.newVersion['detailsWrapper.backButton']}
              icon={faCircleChevronLeft}
              iconSize={24}
              labelVariant={LabelType.titleSmall}
              onPress={() => {
                setNavigationState(NavigationState.List);
                setSelectedMission(undefined);
                setSelectedMissionId(undefined);
              }}
              text={t('back')}
            />
          )}
          <View style={[{ ...styles.containerBase }, { paddingBottom: layout.width < 768 ? 32 : 0 }]}>
            {(layout.width > 768 || (layout.width < 768 && navigationState === NavigationState.List)) && missionBlocks?.length > 0 && (
              <View style={styles.container.sideBar}>
                {!loading ? (
                  <>
                    {missionBlocks && missionBlocks.length > 0 ? (
                      <ScrollView>
                        <View style={{ flexDirection: 'row' }}>
                          <Label variant={LabelType.titleLargeNoCapsPrimary} style={styles.newVersion.list.status}>
                            {t('my_missions')}
                          </Label>
                          <MoreInfoButton title={t('help_support')} openURL={'my-missions-lanla-website-support-link'}>{`${t(
                            'help.when-you-choose-a-mission'
                          )}`}</MoreInfoButton>
                        </View>
                        {missionBlocks &&
                          missionBlocks.map((block, blockIndex) => {
                            return (
                              <View key={`missionBlock_${blockIndex}_${block.status ?? ''}`}>
                                <Label variant={LabelType.titlePrimarySmall} style={styles.newVersion.list.status}>
                                  {t(MissionAssignationStatus[block.status])}
                                </Label>
                                {block.missionsList &&
                                  block.missionsList.map((mission, missionIndex) => {
                                    return (
                                      <Pressable
                                        key={`myMission_${missionIndex}_${mission.id}`}
                                        style={[styles.newVersion.list.item, mission.id === selectedMissionId ? styles.newVersion.list.itemSelected : {}]}
                                        onPress={() => {
                                          setSelectedMissionId(mission.id);
                                          setSelectedMission(mission);

                                          if (Platform.OS === 'web') {
                                            window.history.pushState({}, '', `/mymissions`);
                                          }

                                          if (layout.width <= 768) {
                                            setNavigationState(NavigationState.Details);
                                          }
                                        }}
                                      >
                                        <View style={styles.newVersion.list.logoBase}>{getLogo(mission.logoUrl, mission.hasLogo)}</View>
                                        <View style={{ flexShrink: 1 }}>
                                          <Label
                                            numberOfLines={2}
                                            style={{
                                              color: theme.text.primary,
                                              fontSize: 16,
                                              textTransform: 'uppercase',
                                              fontWeight: '600', // mission.id === selectedMissionId ? '600' : '400',
                                            }}
                                          >{`${mission.missionConfigurationName}`}</Label>
                                          <Label
                                            numberOfLines={2}
                                            style={{ color: theme.text.primary, fontSize: 13, fontWeight: '600', marginBottom: 4 }}
                                          >{`${mission.name}`}</Label>
                                          <Label style={{ color: theme.text.primary, fontSize: 12, fontWeight: '400' }}>{`${mission.address}`}</Label>
                                        </View>
                                      </Pressable>
                                    );
                                  })}
                              </View>
                            );
                          })}
                      </ScrollView>
                    ) : (
                      <>
                        <View style={{ flexDirection: 'row' }}>
                          <Label variant={LabelType.titleLargeNoCapsPrimary} style={styles.newVersion.list.status}>
                            {t('my_missions')}
                          </Label>
                          <MoreInfoButton title={t('help_support')}>
                            {`# Rudis inferius

## Multis sis velox quercus usus frequens Minervaetransformabantur

Lorem markdownum obligor repetam dignabere qua Sigea crinem Cillan succrescere
tollit meliora quem. Auro ferunt? At Aventino ferunt tamen
[nodosque](http://salutant.net/est), et cognovit rurisque **in illud manibus**
ager, [omnia](http://quamquam.net/) in vulnera. Telum conscendunt accensis
hinnitus gravis claudit. Est tantum Perimelen iuratus Cerberei signum atra moras
finibus volat [navis margine omnes](http://in.net/) Cybeleius.

> Unde **mea onus** Verque, nec obstaret, sint, unguibus ut dente Pithecusas
> nulla, cadunt nec agitur. Reminiscor quantas ab vulnera Oeneus et poterat sub
> media eminet, Phoebe. Hiatu excipiunt quodque [efficiet
> arma](http://www.quo.net/in-dixerunt.html), tridente ut undas nutritur
> Credulitas pectora undarum quae durat cultu motus! Volvitur et populis ducis
> matri reserato **inque**, magnum quantumque, nomenque apparuit. Vitamque et
> iniqua magnanimi et aether tacitorum tibi Aethiopum, eum illi domo gaudete
> tenus miserum.

Oculi iam vidit nisi, artesque ac vicibus reice, altum sentit crinitas prope,
vix. Sed o commentaque, superatae exierat vulgi, quotiensque nais haerentem
quaque cunasque lucibus, memorare se. Satis coniunx: nec sic manu tuum est
duritiem diversa.`}
                          </MoreInfoButton>
                        </View>
                        <View style={{ flexGrow: 1, flexShrink: 1 }}>
                          <Label style={{ textAlign: 'center', width: '100%', margin: 'auto', paddingLeft: 16, paddingRight: 16 }} variant={LabelType.title}>
                            No missions message. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          </Label>
                        </View>
                      </>
                    )}
                  </>
                ) : (
                  <View style={{ flexGrow: 1 }}>
                    <Loading style={{ height: 200 }} text={tMission('loading_missions')} />
                  </View>
                )}
              </View>
            )}
            {(layout.width > 768 || (layout.width <= 768 && navigationState === NavigationState.Details)) && missionBlocks?.length > 0 && (
              <>
                {detail && (
                  <View style={styles.newVersion.detailsContainer}>
                    {!loadingDetail ? (
                      <ScrollView style={styles.newVersion.detailsWrapper}>
                        <View style={styles.newVersion.details.header.container}>
                          <View>
                            <Image style={styles.newVersion.details.header.pin} source={pin} />
                          </View>
                          <View style={styles.newVersion.details.header.title}>
                            <Label
                              numberOfLines={2}
                              style={{
                                color: theme.text.primary,
                                fontSize: 16,
                                textTransform: 'uppercase',
                                fontWeight: '600',
                                flexShrink: 1,
                              }}
                            >
                              {selectedMission?.missionConfigurationName}
                            </Label>
                            <Label numberOfLines={2} style={{ color: theme.text.primary, fontSize: 13, fontWeight: '600', marginBottom: 4, flexShrink: 1 }}>
                              {selectedMission?.name}
                            </Label>
                            <Pressable
                              onPress={() => {
                                dispatch(missionsActions.setSelectedBranch({ id: undefined, lat: undefined, lng: undefined }));
                                navigation.navigate({
                                  name: 'map',
                                  params: { pos: selectedMission.branchId, location: `${selectedMission.geoLocation.y}|${selectedMission.geoLocation.x}` },
                                } as never);
                              }}
                              style={{ flexDirection: 'row', alignItems: 'center' }}
                            >
                              <Label variant={LabelType.titleDarkSmall} style={{ flexShrink: 1 }}>
                                {selectedMission?.address}
                              </Label>
                              <FontAwesomeIcon icon={faMapLocationDot} size={14} style={{ marginLeft: 4 }} color={theme.text.titleDark.toString()} />
                            </Pressable>
                            <>
                              {!!selectedMission?.phone && (
                                <Label variant={LabelType.titleDarkSmall} style={{ flexShrink: 1 }}>
                                  {selectedMission?.phone}
                                </Label>
                              )}
                            </>

                            <Label variant={LabelType.caption} style={{ color: theme.text.titleLight, marginTop: 4 }}>
                              {detail?.tags === '' ? 'N/A' : detail?.tags}
                            </Label>
                          </View>
                          <View style={styles.newVersion.details.header.submit}>
                            {(detail.status === MissionAssignationStatus.Assigned || detail.status === MissionAssignationStatus.ValidationRejected) && (
                              <View style={{ gap: 8, alignItems: 'center', width: layout.width <= 768 ? '100%' : 'auto' }}>
                                {detail.daysLeft !== null && (
                                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <View>
                                      <Label style={{ fontSize: 12, color: theme.text.primary, marginTop: -4 }}>
                                        {detail.status === MissionAssignationStatus.ValidationRejected ? t('Validation-failed') : t('Mission-expires-the')}
                                      </Label>
                                      {detail.status !== MissionAssignationStatus.ValidationRejected && (
                                        <Label variant={LabelType.titleSmall} style={{ fontWeight: '500', flexShrink: 1 }} numberOfLines={2}>
                                          {detail.endDate.toLocaleString()}
                                        </Label>
                                      )}
                                    </View>
                                    <MoreInfoButton
                                      style={{ marginLeft: 8 }}
                                      title={''}
                                      color={theme.text.primary.toString()}
                                      size={16}
                                      openURL={'complete-the-questionnaire-lanla-website-support-link'}
                                    >
                                      {`${t('help.complete-the-questionnaire')}`}
                                    </MoreInfoButton>
                                  </View>
                                )}
                                {detail.isExtendable && (
                                  <Pressable
                                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -8 }}
                                    onPress={() => handleDelayMissionClick(selectedMissionId, detail.maxExtendableDays)}
                                  >
                                    <FontAwesomeIcon icon={faCalendarClock} size={16} color={'rgb(52, 114, 184)'} />
                                    <Label
                                      variant={LabelType.title}
                                      style={[styles.newVersion.details.content.delayMission.text, { marginLeft: 4 }]}
                                      numberOfLines={3}
                                    >
                                      {t('Postpone-mission')}
                                    </Label>
                                  </Pressable>
                                )}
                                <View style={{ flexDirection: 'row' }}>
                                  <CustomButton
                                    title={
                                      detail.status === MissionAssignationStatus.ValidationRejected
                                        ? t('Correct-my-questionnaire')
                                        : t('Complete-the-questionnaire')
                                    }
                                    onPress={showPrompt}
                                  />
                                </View>
                              </View>
                            )}
                          </View>
                        </View>
                        {detail && (
                          <View style={styles.newVersion.details.content.container}>
                            <View style={styles.newVersion.details.content.leftContainer}>
                              {detail.supportedCultureCodes?.length > 0 && (
                                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                                  <Label style={{ fontSize: 11, color: theme.text.error, fontWeight: '500' }}>
                                    {t('Mission-in')} {detail.supportedCultureCodes?.map((x) => t(x)).join(', ')} {t('only')}!
                                  </Label>
                                  <MoreInfoButton style={{ marginLeft: 8 }} title={''} color={theme.text.primary.toString()} size={12}>
                                    {t('help.mission-in-language-only').format(detail.supportedCultureCodes?.map((x) => t(x)).join(', '))}
                                  </MoreInfoButton>
                                </View>
                              )}
                              <View style={styles.newVersion.details.content.timeline.container}>
                                {getTimeLineItem('Mission-accepted', faLocation, detail.createdDate)}
                                {getTimeLineItem('Mission-accomplished', faLocationCheck, detail.submittedDate)}
                                {getTimeLineItem('Mission-in-prevalidation', faEye, detail.submittedDate)}
                                {getTimeLineItem('Mission-in-validation', faCheckDouble, detail.submittedDate)}
                                {getTimeLineItem('Mission-approved', faAward)}
                              </View>
                              <View style={styles.newVersion.details.content.summary.container}>
                                {false && detail.tags != undefined && detail.tags != '' && (
                                  <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItem}>
                                    <FontAwesomeIcon
                                      icon={faStore}
                                      color={theme.text.primary.toString()}
                                      size={20}
                                      style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                    />
                                    <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                      {detail.tags}
                                    </Label>
                                  </View>
                                )}
                                <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItem}>
                                  <FontAwesomeIcon
                                    icon={faAward}
                                    color={theme.text.primary.toString()}
                                    style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                    size={20}
                                  />
                                  <Label variant={LabelType.valueLargeMission} style={{ marginLeft: 6, minWidth: 50, flexShrink: 0 }}>
                                    {userPrefs.uiLanguage === 'en' ? `$${detail.salaryAmount ?? 0}` : `${detail.salaryAmount ?? 0} $`}
                                  </Label>
                                  <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemValueSecondary}>
                                    <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                      {t('salary')}
                                    </Label>
                                    <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                      ({detail.salaryPoints ?? 0} pts)
                                    </Label>
                                  </View>
                                  <MoreInfoButton style={{ alignSelf: 'flex-start', marginLeft: 8 }} title={''}>
                                    {`${t('help.points-rate')}`}
                                  </MoreInfoButton>
                                </View>
                                {detail.requiredPurchase && (
                                  <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItem}>
                                    <FontAwesomeIcon
                                      icon={faBasketShopping}
                                      color={theme.text.primary.toString()}
                                      style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                      size={20}
                                    />
                                    <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }}>
                                      {t('purchase_required')}
                                    </Label>
                                  </View>
                                )}
                                {detail.reimbursementValue != undefined && detail.reimbursementValue != '' && (
                                  <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItem}>
                                    <FontAwesomeIcon
                                      icon={faReceipt}
                                      color={theme.text.primary.toString()}
                                      style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                      size={20}
                                    />
                                    <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemValue}>
                                      <Label
                                        variant={LabelType.valueLargeMission}
                                        style={{ marginLeft: 6, minWidth: 50, flexShrink: 0 }}
                                      >{`${detail.reimbursementValue}`}</Label>
                                      <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemValueSecondary}>
                                        <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, flexShrink: 1, fontWeight: '700' }}>
                                          {`${t('reimbursement-up-to')} ${detail.reimbursementValue}`}
                                        </Label>
                                        {detail.reimbursementMaxAmount > 0 && (
                                          <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, flexShrink: 1, fontWeight: '700' }}>
                                            Max {detail.reimbursementMaxAmount} $ ({detail.reimbursementMaxPoints} pts)
                                          </Label>
                                        )}
                                      </View>
                                    </View>
                                    <MoreInfoButton style={{ alignSelf: 'flex-start', marginLeft: 8 }} title={''}>
                                      {`${t('help.points-rate')}`}
                                    </MoreInfoButton>
                                  </View>
                                )}
                                {detail.time != undefined && (
                                  <View style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItem}>
                                    <FontAwesomeIcon
                                      icon={faCalendar}
                                      color={theme.text.primary.toString()}
                                      style={stylesDetail.missionsBranchDetail.content.infoContainer.summaryItemIcon}
                                      size={20}
                                    />
                                    <Label variant={LabelType.titleDarkSmall} style={{ marginLeft: 6, fontWeight: '700' }} numberOfLines={10}>
                                      {detail.time}
                                    </Label>
                                  </View>
                                )}
                              </View>
                              <View style={styles.newVersion.details.content.actionButtonsContainer}>
                                {postponeLoading ? (
                                  <View style={{ height: 40, marginBottom: 16 }}>
                                    <Loading />
                                  </View>
                                ) : (
                                  <View style={{ display: 'flex', gap: 16 }}>
                                    {extendMissionFailed && (
                                      <Label style={{ color: theme.text.error, fontWeight: '600', flexShrink: 0 }} numberOfLines={5}>
                                        {t('extend-mission-fail')}
                                      </Label>
                                    )}
                                    {detail.isExtendable && (
                                      <Pressable
                                        style={styles.newVersion.details.content.delayMission.container}
                                        onPress={() => handleDelayMissionClick(selectedMissionId, detail.maxExtendableDays)}
                                      >
                                        <FontAwesomeIcon icon={faCalendarClock} size={Platform.OS === 'web' ? 32 : 24} color={'rgb(52, 114, 184)'} />
                                        <Label variant={LabelType.title} style={styles.newVersion.details.content.delayMission.text} numberOfLines={3}>
                                          {t('Postpone-mission')}
                                        </Label>
                                      </Pressable>
                                    )}
                                  </View>
                                )}
                                {layout.width > 572 &&
                                  (detail.status < MissionAssignationStatus.Prevalidation || detail.status == MissionAssignationStatus.ValidationRejected) && (
                                    <Pressable
                                      style={[styles.newVersion.details.content.cancelMission.container, { marginBottom: 16 }]}
                                      onPress={() => {
                                        showConfirmationPrompt();
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faCircleXmark} size={Platform.OS === 'web' ? 32 : 24} color={'rgb(138, 47, 72)'} />
                                      <Label variant={LabelType.title} style={styles.newVersion.details.content.cancelMission.text}>
                                        {t('Cancel-mission')}
                                      </Label>
                                      <MoreInfoButton
                                        style={{ marginLeft: 8 }}
                                        title={''}
                                        color={'rgb(138, 47, 72)'}
                                        openURL={'cancel-the-mission-lanla-website-support-link'}
                                      >
                                        {`${t('help.cancel-the-mission')}`}
                                      </MoreInfoButton>
                                    </Pressable>
                                  )}

                                <Pressable
                                  style={[styles.newVersion.details.content.cancelMission.container, { backgroundColor: '#e9f5f3' }]}
                                  onPress={() =>
                                    detailsState === MissionDetailsState.Scenario
                                      ? setDetailsState(MissionDetailsState.Mission)
                                      : setDetailsState(MissionDetailsState.Scenario)
                                  }
                                >
                                  <FontAwesomeIcon icon={faUserSecret} size={Platform.OS === 'web' ? 32 : 24} color={'#43a49e'} />
                                  <Label
                                    variant={LabelType.title}
                                    style={[styles.newVersion.details.content.cancelMission.text, { color: '#43a49e', textDecorationColor: '#43a49e' }]}
                                  >
                                    {detailsState !== MissionDetailsState.Scenario ? t('Consult-the-scenario') : t('Check-the-status')}
                                  </Label>
                                </Pressable>
                              </View>
                            </View>
                            {getDetailComponent()}
                            <View style={{ ...styles.newVersion.details.content.actionButtonsContainer, marginBottom: 48, marginTop: -16 }}>
                              {layout.width <= 572 &&
                                (detail.status < MissionAssignationStatus.Prevalidation || detail.status == MissionAssignationStatus.ValidationRejected) && (
                                  <Pressable
                                    style={styles.newVersion.details.content.cancelMission.container}
                                    onPress={() => {
                                      showConfirmationPrompt();
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCircleXmark} size={Platform.OS === 'web' ? 32 : 24} color={'rgb(138, 47, 72)'} />
                                    <Label variant={LabelType.title} style={styles.newVersion.details.content.cancelMission.text}>
                                      {t('Cancel-mission')}
                                    </Label>
                                    <MoreInfoButton
                                      style={{ marginLeft: 8 }}
                                      title={''}
                                      color={'rgb(138, 47, 72)'}
                                      openURL={'cancel-the-mission-lanla-website-support-link'}
                                    >
                                      {`${t('help.cancel-the-mission')}`}
                                    </MoreInfoButton>
                                  </Pressable>
                                )}
                            </View>
                          </View>
                        )}
                      </ScrollView>
                    ) : (
                      <View style={{ flexGrow: 1 }}>
                        <Loading style={{ height: 200 }} />
                      </View>
                    )}
                  </View>
                )}
              </>
            )}
            {!loading && (!missionBlocks || missionBlocks.length === 0) && getEmptyComponent()}
          </View>
        </>
      )}
    </AuthGuard>
  );
};

export default MyMissions;
