import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import CustomButton from '../common/CustomButton';
import Label, { LabelType } from '../common/Label';
import useTranslation from '../../hooks/translation-hook';
import CustomTextInput, { InputTypeValidation } from '../common/CustomTextInput';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import { authActions } from '../../models/helpers/platformImportHelper';
import useCustomTheme from '../../hooks/theme-hook';
import { StylesHelper } from '../../models/helpers/stylesHelper';
import Loading from '../common/Loading';
import { getAuth, getMultiFactorResolver, PhoneAuthProvider, RecaptchaVerifier, PhoneMultiFactorGenerator } from 'firebase/auth';

export interface ReauthenticateUserFormProps {
  onReauthenticate: () => void;
}

const ReauthenticateUserForm = (props: ReauthenticateUserFormProps) => {
  const auth = getAuth();
  const t = useTranslation();
  const tLogin = useTranslation({ defaultPath: 'login' });
  const dispatch = useAppDispatch();
  const theme = useCustomTheme();
  const authStore = useAppSelector((selector) => selector.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userMessage, setUserMessage] = useState<string | undefined>();
  const [emailLoginValidationError, setEmailLoginValidationError] = useState<string | undefined>(undefined);
  const [passwordLoginValidationError, setPasswordLoginValidationError] = useState<string | undefined>(undefined);
  const [passwordLoginInputValidationError, setPasswordLoginInputValidationError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [phoneVerificationId, setPhoneVerificationId] = useState<string>();
  const [resolver, setResolver] = useState(null);
  const [phoneInfoOptions, setPhoneInfoOptions] = useState<any>({});
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [show2fa, setShow2fa] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setRecaptchaVerifier(
      new RecaptchaVerifier(auth, '2fa-recaptcha-container', {
        size: 'invisible',
        callback: (response) => {},
      })
    );
  }, []);

  const gotLoginInfo = (response) => {
    console.log('login response', response);
    setLoading(false);

    if (response.code == 'auth/wrong-password') {
      setUserMessage(tLogin('invalid-credentials'));
      return;
    }

    if (response.code === 'auth/too-many-requests') {
      setUserMessage(tLogin('too-many-request-login'));
      return;
    }

    if (response.code == 'auth/user-not-found') {
      setUserMessage(tLogin('invalid-credentials'));
      return;
    }

    if (response.code == 'auth/multi-factor-auth-required') {
      if (phoneVerificationId == undefined) {
        let resolver = getMultiFactorResolver(auth, response);
        setResolver(resolver);
        let phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        setPhoneInfoOptions(phoneInfoOptions);
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(function (verificationId) {
          setPhoneVerificationId(verificationId);
        });
      }
      setShow2fa(true);
      return;
    }

    if (response.email || response.user.email) {
      props.onReauthenticate && props.onReauthenticate();
    }
  };

  const verify2fa = () => {
    setVerificationCodeError(undefined);
    const cred = PhoneAuthProvider.credential(phoneVerificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    setLoading(true);
    resolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {
        setPhoneVerificationId(undefined);
        gotLoginInfo(userCredential);
      })
      .catch(function (error) {
        if (error.code == 'auth/invalid-verification-code') {
          setVerificationCodeError(t('Invalid-verification-code'));
        } else {
          setVerificationCodeError(t('Something-went-wrong-please-try-again-later'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReauthenticate = (email, password) => {
    if (!email || email.trim() === '') {
      setEmailLoginValidationError(tLogin('email_required'));
      return;
    }

    if (!password || password.trim() === '') {
      setPasswordLoginValidationError(tLogin('password_required'));
      return;
    }

    if (email?.toLocaleLowerCase() != authStore?.user?.email?.toLocaleLowerCase()) {
      setUserMessage(tLogin('invalid-credentials'));
      return;
    }

    if (email && password) {
      setUserMessage(undefined);
      setLoading(true);
      dispatch(authActions.reauthenticate({ email: email.trim(), password, callback: gotLoginInfo }));
    }
  };

  return (
    <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
      <View id={'2fa-recaptcha-container'}>{/* recaptcha used for account verification */}</View>
      {!show2fa ? (
        <>
          <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
            <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
              {t('Reauthenticate')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('Please-log-back-in')}
            </Label>
            <>
              {userMessage && (userMessage?.length ?? 0) > 0 && (
                <Label
                  numberOfLines={5}
                  variant={LabelType.titleSmall}
                  style={{ padding: 16, marginTop: 24, backgroundColor: theme.primary, ...StylesHelper.borderRadius(8) }}
                >
                  {userMessage}
                </Label>
              )}
            </>
            <Label style={{ fontSize: 14, marginTop: 8 }}>{t('email')}</Label>
            <CustomTextInput
              style={{ marginTop: 4 }}
              required
              inputTypeValidation={InputTypeValidation.email}
              onValidationChange={(v) => (v.valid ? setEmailLoginValidationError(undefined) : setEmailLoginValidationError(v.message))}
              placeholder={t('email')}
              requiredMessage={tLogin('email_required')}
              key={2}
              value={email}
              onChange={(e) => {
                const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
                setEmail(text);
              }}
              onKeyPress={(e) => {
                {
                  if ((e as any).code === 'Enter') {
                    handleReauthenticate(email, password);
                  }
                }
              }}
            />
            <>{emailLoginValidationError && <Label variant={LabelType.validationError}>{emailLoginValidationError}</Label>}</>
            <Label style={{ fontSize: 14, marginTop: 8 }}>{t('password')}</Label>
            <CustomTextInput
              style={{ marginTop: 4 }}
              required
              requiredMessage={tLogin('password_required')}
              onValidationChange={(v) => (v.valid ? setPasswordLoginInputValidationError(undefined) : setPasswordLoginInputValidationError(v.message))}
              secureTextEntry={true}
              placeholder={t('password')}
              key={1}
              value={password}
              onChange={(e) => {
                const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
                setPassword(text);
              }}
              onKeyPress={(e) => {
                if ((e as any).code === 'Enter') {
                  handleReauthenticate(email, password);
                }
              }}
            />
            <>
              {(passwordLoginValidationError || passwordLoginInputValidationError) && (
                <Label variant={LabelType.validationError} numberOfLines={10}>
                  {passwordLoginValidationError ? passwordLoginValidationError : passwordLoginInputValidationError}
                </Label>
              )}
            </>
          </View>
          {loading ? (
            <View style={{ width: '100%', height: 50 }}>
              <Loading />
            </View>
          ) : (
            <CustomButton
              style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
              textStyle={{ color: 'white' }}
              title={t('Reauthenticate')}
              onPress={() => handleReauthenticate(email, password)}
            />
          )}
        </>
      ) : (
        <>
          <View style={{ marginBottom: 24 }}>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('We-have-sent-a-verification-code-to')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: '#1C4259', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {`${phoneInfoOptions.multiFactorHint.phoneNumber}`}
            </Label>
            <CustomTextInput
              style={{ marginTop: 24, marginBottom: 24 }}
              placeholder={t('Verification-Code')}
              onChange={(e) => {
                setVerificationCode(e.nativeEvent.text);
              }}
              value={verificationCode}
            />
            {verificationCodeError != undefined && <Label variant={LabelType.validationError}>{verificationCodeError}</Label>}
          </View>
          {loading ? (
            <View style={{ width: '100%', height: 50 }}>
              <Loading />
            </View>
          ) : (
            <CustomButton
              style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
              textStyle={{ color: 'white' }}
              title={t('Reauthenticate')}
              onPress={verify2fa}
            />
          )}
        </>
      )}
    </View>
  );
};

export default ReauthenticateUserForm;
